import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app';
import { PATH_HOME } from '../common/constants/paths';

export default defineNuxtRouteMiddleware(() => {
  const { data } = useAuth();
  const fundingSourcesStore = useFundingSources();
  const $auth = data.value ?? null;

  if (!$auth) {
    return;
  }

  if (
    !isVerificationInProgress(
      $auth,
      fundingSourcesStore.checkfloProfile,
      fundingSourcesStore.bankForPayments?.id.toString() ?? '',
    )
  ) {
    return navigateTo({ path: PATH_HOME });
  }
});
